// components
import Seo from 'src/components/Seo';
// hooks
import useFaqMetaData from 'src/cms/data/faq/useFaqMetaData';
// types
import type { HeadFC } from 'gatsby';

export const Head: HeadFC = ({ location }) => {
  const faqMetaData = useFaqMetaData();

  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={faqMetaData?.title}
      description={faqMetaData?.description}
      schema={{
        ...faqMetaData?.schema,
        type: faqMetaData?.schema?.type ?? 'FAQ',
      }}
    />
  );
};

export { default } from './Faq';
